<template>
  <div class="mainInfo">
    <div
      v-for="(item, index) in data"
      :key="index"
      style="padding: 10px 0 0 10px"
    >
      <el-form label-width="100px" :model="item">
        <el-form-item label="下游一级：">
          <div class="flex_box">
            <div>
              <el-input v-model="item.name" size="small"></el-input>
              <el-dropdown>
                <span class="el-dropdown-link" style="cursor: pointer">
                  <i class="el-icon-circle-plus-outline"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="add('one')"
                    >添加一级</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="add('two')"
                    >添加一、二级</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="add('three')"
                    >添加一、二、三级</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="add('four')"
                    >添加一、二、三、四级</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="add('all')"
                    >添加所有下级</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <i
                @click="delet(index)"
                class="el-icon-remove-outline"
                v-if="index > 0"
              ></i>
            </div>
            <div class="compny_flex">
              <div>
                <span>行业类别：</span>
              </div>
              <el-cascader
                v-model="item.list"
                :options="industryThreeOptions"
                :props="industryThreeProps"
                ref="industryThree"
                @change="handleContentChaChange(item)"
                clearable
                collapse-tags
              ></el-cascader>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="一级说明：">
          <el-input
            v-model="item.industrThat"
            type="textarea"
            :autosize="{ minRows: 1, maxRows: 20 }"
          ></el-input>
        </el-form-item>
        <div
          v-for="(el, indexSmall) in item.children"
          :key="indexSmall"
          style="padding-left: 100px"
        >
          <el-form-item label="下游二级：">
           <div class="flex_box">
            <div>
              <el-input v-model="el.name" size="small"></el-input>
            <el-dropdown>
              <span class="el-dropdown-link" style="cursor: pointer">
                <i class="el-icon-circle-plus-outline"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="addmiddle(index, 'one')"
                  >添加二级</el-dropdown-item
                >
                <el-dropdown-item @click.native="addmiddle(index, 'two')"
                  >添加二、三级</el-dropdown-item
                >
                <el-dropdown-item @click.native="addmiddle(index, 'three')"
                  >添加二、三、四级</el-dropdown-item
                >
                <el-dropdown-item @click.native="addmiddle(index, 'all')"
                  >添加所有下级</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <i
              @click="deletSecond(index, indexSmall)"
              class="el-icon-remove-outline"
            ></i>
            </div>
            <div class="compny_flex">
              <div>
                <span>行业类别：</span>
              </div>
              <el-cascader
                v-model="el.list"
                :options="industryThreeOptions"
                :props="industryThreeProps"
                ref="industryThree"
                @change="handleContentChaChange(el)"
                clearable
                collapse-tags
              ></el-cascader>
            </div>
           </div>
          </el-form-item>
          <el-form-item label="二级说明：">
            <el-input
              v-model="el.industrThat"
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 20 }"
            ></el-input>
          </el-form-item>
          <div
            v-for="(item1, index1) in el.children"
            :key="index1"
            style="padding-left: 100px"
          >
            <el-form-item label="下游三级：">
             <div class="flex_box">
              <div>
                <el-input v-model="item1.name" size="small"></el-input>
              <el-dropdown>
                <span class="el-dropdown-link" style="cursor: pointer">
                  <i class="el-icon-circle-plus-outline"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    @click.native="addsmall(index, indexSmall, 'one')"
                    >添加三级</el-dropdown-item
                  >
                  <el-dropdown-item
                    @click.native="addsmall(index, indexSmall, 'two')"
                    >添加三、四级</el-dropdown-item
                  >
                  <el-dropdown-item
                    @click.native="addsmall(index, indexSmall, 'all')"
                    >添加所有下级</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <i
                @click="deleteThird(index, indexSmall, index1)"
                class="el-icon-remove-outline"
              ></i>
              </div>
              <div class="compny_flex">
              <div>
                <span>行业类别：</span>
              </div>
              <el-cascader
                v-model="item1.list"
                :options="industryThreeOptions"
                :props="industryThreeProps"
                ref="industryThree"
                @change="handleContentChaChange(item1)"
                clearable
                collapse-tags
              ></el-cascader>
            </div>
             </div>
            </el-form-item>
            <el-form-item label="三级说明：">
              <el-input
                v-model="item1.industrThat"
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 20 }"
              ></el-input>
            </el-form-item>
            <div
              v-for="(item2, index2) in item1.children"
              :key="index2"
              style="padding-left: 100px"
            >
              <el-form-item label="下游四级：">
                <div class="flex_box">
                  <div>
                    <el-input v-model="item2.name" size="small"></el-input>
                <el-dropdown>
                  <span class="el-dropdown-link" style="cursor: pointer">
                    <i class="el-icon-circle-plus-outline"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      @click.native="addFour(index, indexSmall, index1, 'one')"
                      >添加四级</el-dropdown-item
                    >
                    <el-dropdown-item
                      @click.native="addFour(index, indexSmall, index1, 'all')"
                      >添加所有下级</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
                <i
                  @click="deleteFour(index, indexSmall, index1, index2)"
                  class="el-icon-remove-outline"
                ></i>
                  </div>
                  <div class="compny_flex">
              <div>
                <span>行业类别：</span>
              </div>
              <el-cascader
                v-model="item2.list"
                :options="industryThreeOptions"
                :props="industryThreeProps"
                ref="industryThree"
                @change="handleContentChaChange(item2)"
                clearable
                collapse-tags
              ></el-cascader>
            </div>
                </div>
              </el-form-item>
              <el-form-item label="四级说明：">
                <el-input
                  v-model="item2.industrThat"
                  type="textarea"
                  :autosize="{ minRows: 1, maxRows: 20 }"
                ></el-input>
              </el-form-item>
              <div
                v-for="(item3, index3) in item2.children"
                :key="index3"
                style="padding-left: 100px"
              >
                <el-form-item label="下游五级：">
                 <div class="flex_box">
                  <div>
                    <el-input v-model="item3.name" size="small"></el-input>
                  <i
                    @click="addFive(index, indexSmall, index1, index2)"
                    class="el-icon-circle-plus-outline"
                  ></i>
                  <i
                    @click="
                      deleteFive(index, indexSmall, index1, index2, index3)
                    "
                    class="el-icon-remove-outline"
                  ></i>
                  </div>
                  <div class="compny_flex">
              <div>
                <span>行业类别：</span>
              </div>
              <el-cascader
                v-model="item3.list"
                :options="industryThreeOptions"
                :props="industryThreeProps"
                ref="industryThree"
                @change="handleContentChaChange(item3)"
                clearable
                collapse-tags
              ></el-cascader>
            </div>
                 </div>
                </el-form-item>
                <el-form-item label="五级说明：">
                  <el-input
                    v-model="item3.industrThat"
                    type="textarea"
                    :autosize="{ minRows: 1, maxRows: 20 }"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getIndustryList } from "@/api/common.js";
export default {
  created() {
    this.getIndustryThree();
    setTimeout(() => {
      if (this.personType) {
        if (this.editTalentInfo.下游.length > 0) {
          this.data = this.editTalentInfo.下游;
        }
      } else if (!this.personType) {
      }
    }, 500);
  },
  props: {
    editTalentInfo: {
      type: Object,
    },
    personType: {
      type: Number,
    },
  },
  data() {
    return {
      //行业标签
      industryThreeOptions: [],
      industryThreeProps: {
        multiple: true,
        value: "id",
        label: "industryName",
        children: "childNode",
      },
      basicData: {
        categoryExplain: "",
        industryType: "",
      },
      loading: true,
      data: [
        // 一级
        {
          industrThat: "",
          name: "",
          children: [
            // 二级
            {
              industrThat: "",
              name: "",
              children: [
                // 三级
                {
                  industrThat: "",
                  name: "",
                  children: [
                    // 四级
                    {
                      industrThat: "",
                      name: "",
                      children: [
                        // 五级
                        {
                          industrThat: "",
                          name: "",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };
  },
  methods: {
    async getIndustryThree() {
      const res = await getIndustryList();
      if (res.code == 200) {
        this.industryThreeOptions = res.data;
      }
    },
    handleContentChaChange(item) {
      item.structLabels = item.list.map((el, index) => {
        return el.join(",");
      });
    },
    delet(index) {
      this.data.splice(index, 1);
    },
    deletSecond(index, indexSmall) {
      this.data[index].children.splice(indexSmall, 1);
    },
    deleteThird(index, indexSmall, index1) {
      this.data[index].children[indexSmall].children.splice(index1, 1);
    },
    deleteFour(index, indexSmall, index1, index2) {
      this.data[index].children[indexSmall].children[index1].children.splice(
        index2,
        1
      );
    },
    deleteFive(index, indexSmall, index1, index2, index3) {
      this.data[index].children[indexSmall].children[index1].children[
        index2
      ].children.splice(index3, 1);
    },
    add(data) {
      let value = {};
      if (data == "all") {
        value = {
          industrThat: "",
          name: "",
          children: [
            // 一级
            {
              industrThat: "",
              name: "",
              children: [
                // 二级
                {
                  industrThat: "",
                  name: "",
                  children: [
                    // 三级
                    {
                      industrThat: "",
                      name: "",
                      children: [
                        // 四级
                        {
                          industrThat: "",
                          name: "",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        };
      } else if (data == "four") {
        value = {
          industrThat: "",
          name: "",
          children: [
            // 一级
            {
              industrThat: "",
              name: "",
              children: [
                // 二级
                {
                  industrThat: "",
                  name: "",
                  children: [
                    // 三级
                    {
                      industrThat: "",
                      name: "",
                    },
                  ],
                },
              ],
            },
          ],
        };
      } else if (data == "three") {
        value = {
          industrThat: "",
          name: "",
          children: [
            // 一级
            {
              industrThat: "",
              name: "",
              children: [
                // 二级
                {
                  industrThat: "",
                  name: "",
                },
              ],
            },
          ],
        };
      } else if (data == "two") {
        value = {
          industrThat: "",
          name: "",
          children: [
            // 一级
            {
              industrThat: "",
              name: "",
            },
          ],
        };
      } else if (data == "one") {
        value = {
          industrThat: "",
          name: "",
        };
      }

      this.data.push(value);
    },
    addmiddle(indexBig, data) {
      let value = {};
      if (data == "all") {
        value = {
          industrThat: "",
          name: "",
          children: [
            // 一级
            {
              industrThat: "",
              name: "",
              children: [
                // 二级
                {
                  industrThat: "",
                  name: "",
                  children: [
                    // 三级
                    {
                      industrThat: "",
                      name: "",
                    },
                  ],
                },
              ],
            },
          ],
        };
      } else if (data == "three") {
        value = {
          industrThat: "",
          name: "",
          children: [
            // 一级
            {
              industrThat: "",
              name: "",
              children: [
                // 二级
                {
                  industrThat: "",
                  name: "",
                },
              ],
            },
          ],
        };
      } else if (data == "two") {
        value = {
          industrThat: "",
          name: "",
          children: [
            // 一级
            {
              industrThat: "",
              name: "",
            },
          ],
        };
      } else if (data == "one") {
        value = {
          industrThat: "",
          name: "",
        };
      }
      this.data[indexBig].children.push(value);
    },
    addsmall(indexBig, indexMiddle, data) {
      let value = {};
      if (data == "all") {
        value = {
          industrThat: "",
          name: "",
          children: [
            // 四级
            {
              industrThat: "",
              name: "",
              children: [
                // 五级
                {
                  industrThat: "",
                  name: "",
                },
              ],
            },
          ],
        };
      } else if (data == "two") {
        value = {
          industrThat: "",
          name: "",
          children: [
            // 四级
            {
              industrThat: "",
              name: "",
            },
          ],
        };
      } else if (data == "one") {
        value = {
          industrThat: "",
          name: "",
        };
      }
      this.data[indexBig].children[indexMiddle].children.push(value);
    },
    addFour(index1, index2, index3, data) {
      let value = {};
      if (data == "all") {
        value = {
          industrThat: "",
          name: "",
          children: [
            // 一级
            {
              industrThat: "",
              name: "",
            },
          ],
        };
      } else if (data == "one") {
        value = {
          industrThat: "",
          name: "",
        };
      }
      this.data[index1].children[index2].children[index3].children.push(value);
    },
    addFive(index1, index2, index3, index4) {
      const value = {
        industrThat: "",
        name: "",
        children: [
          // 一级
          {
            industrThat: "",
            name: "",
          },
        ],
      };
      this.data[index1].children[index2].children[index3].children[
        index4
      ].children.push(value);
    },
  },
};
</script>

<style scoped lang="less">
.flex_box {
  display: flex;
  .compny_flex {
    display: flex;
    margin-left: 20px;
  }
}
.mainInfo {
  overflow: scroll;
}
/deep/.el-input {
  width: 370px !important;
}
/deep/.el-textarea {
  width: 80% !important;
  // min-height: 40px !important;
}
/deep/ .el-icon-circle-plus-outline {
  font-size: 31px;
  vertical-align: middle;
  color: #698eef;
  cursor: pointer;
  padding-left: 10px;
}
/deep/ .el-form-item {
  margin-bottom: 5px;
}
/deep/ .el-icon-remove-outline {
  font-size: 31px;
  vertical-align: middle;
  color: red;
  cursor: pointer;
  padding-left: 10px;
}
</style>
