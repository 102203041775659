<template>
  <div v-if="isShow">
    <div class="content">
      <el-form
        label-width="120px"
        :model="chainData"
        ref="chainRef"
        :rules="chainRule"
      >
        <div class="mainTop">
          <el-form-item
            label="产业链名称："
            style="width: 470px"
            prop="supplyChainName"
          >
            <el-input v-model="chainData.supplyChainName"></el-input>
          </el-form-item>
          <el-form-item label="产业链描述：" prop="supplyChainDescribe">
            <el-input
              v-model="chainData.supplyChainDescribe"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 20 }"
              style="80%"
            ></el-input>
          </el-form-item>
          <el-form-item label="行业类别：">
            <dic-checkbox-group
              :code.sync="chainData.supplyChainIndustrys"
              tag="行业"
              @updateCode="updateCode"
            />
          </el-form-item>
          <el-form-item label="产业链图片：">
            <upload-image
              :imageUrl.sync="chainData.imageUrl"
              :saveUrl.sync="chainData.supplyChainImage"
            />
          </el-form-item>
          <el-form-item label="产业链检索：">
            <el-select
              v-model="chainData.father"
              filterable
              placeholder="请输入"
              @change="selectOne"
              remote
              reserve-keyword
              :remote-method="searchTechnologys"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.supplyChainName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上级产业链：">
            <p
              v-if="
                chainData.fatherId == '0' && !chainData.fatherIds.length > 0
              "
              style="color: #7c7f8e;"
            >
              该产业链为一级产业链
            </p>
            <el-tag
              v-for="tag in chainData.fatherIds"
              :key="tag.id"
              closable
              @close="handleClose(tag)"
              style="margin-right:10px"
              v-else
            >
              {{ tag.supplyChainName }}
            </el-tag>
          </el-form-item>
          <el-form-item label="下级产业链：" v-if="personType">
            <el-input
              v-model="chainData.sonIds"
              type="textarea"
              placeholder="该标签无下级标签"
              disabled
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div class="ess_people" v-if="personType">
        <div class="ess_people_list">
          创建人：{{ chainData.supplyChainCreateName }}
        </div>
        <div class="ess_people_list">
          修改人：{{ chainData.supplyChainUpdateName }}
        </div>
        <div class="ess_people_list">
          创建时间：{{ chainData.supplyChainCretaeTime }}
        </div>
        <div class="ess_people_list">
          修改时间：{{ chainData.supplyChainUpdateTime }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import { getComboBox } from "@/api/industrialChain.js";
import UploadImage from "@/components/UploadImage.vue";
export default {
  name: "App",
  components: {
    DicCheckboxGroup,
    UploadImage
  },
  // computed: {
  //   chainData() {
  //     return this.$route.params.chainData
  //   },
  //   personType() {
  //     return this.$route.params.personType
  //   },
  //   Authorization() {
  //     return sessionStorage.getItem('token')
  //   },
  // },
  created() {
    setTimeout(() => {
      if (this.personType) {
        this.chainData = this.chainDatas;
        this.isShow = true;
      } else if (!this.personType) {
        this.isShow = true;
      }
    }, 500);
  },
  props: {
    chainDatas: {
      type: Object
    },
    personType: {
      type: Number
    }
  },
  data() {
    return {
      isShow: false,
      options: [],
      chainData: {
        supplyChainName: "",
        supplyChainDescribe: "",
        supplyChainIndustrys: [],
        supplyChainIndustryChild: [],
        father: "",
        fatherIds: []
      },
      chainRule: {
        supplyChainName: [
          { required: true, message: "请填写产业链名称", trigger: "blur" }
        ],
        supplyChainDescribe: [
          { required: true, message: "请填写产业链描述", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    handleClose(tag) {
      const index = this.chainData.fatherIds.indexOf(tag);
      this.chainData.fatherIds.splice(index, 1);
    },
    async searchTechnologys(query) {
      if (query !== "") {
        setTimeout(async () => {
          const res = await getComboBox({ supplyChainName: query });
          const dataList = res.data;
          this.options = dataList.map(item => {
            return (item = {
              id: item.id,
              supplyChainName: item.supplyChainName
            });
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    selectOne() {
      const valueOne = this.options.filter(
        item => item.id === this.chainData.father
      );
      this.chainData.fatherIds.push(valueOne[0]);
    },
    updateCode(val, tag) {
      switch (tag) {
        case "领域":
          this.chainData.supplyChainIndustryChild = val;
          break;

        case "行业":
          this.chainData.supplyChainIndustryChild = val;
          break;

        case "技术":
          this.chainData.supplyChainIndustryChild = val;
          break;

        default:
          break;
      }
    }
  }
};
</script>

<style lang="less" scoped>
// 上传
/deep/ .el-upload--picture-card {
  width: 200px !important;
  height: 150px !important;
  line-height: 150px !important;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 200px;
  height: 150px;
  // line-height: 150px;
}
/deep/ .el-upload {
  background-color: #fff;
}
/deep/.el-input {
  width: 470px !important;
}
svg {
  font-size: 14px;
}
.mainTop {
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
}

.content {
  overflow: auto;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px; /*滚动条的圆角*/
  background-color: grey; /*滚动条的背景颜色*/
}
.ess_people {
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  margin: 20px 30px;
  align-content: center;

  .ess_people_list {
    width: 100%;
    margin: 4px 0;
    color: #7c7f8e;
    font-size: 12px;
  }
}
</style>
