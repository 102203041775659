import request from "@/utils/request";

// 浏览信息
export function getChain(data) {
  return request({
    url: "/system/industrialChain/getIndustrialChainList",
    method: "post",
    data
  });
}

// 浏览上级产业链
export function getComboBox(data) {
  return request({
    url: "/system/industrialChain/getComboBox",
    method: "get",
    params:data
  });
}

// 单个查询
export function getChainOne(data) {
  return request({
    url: "/system/industrialChain/getIndustrialChainByid",
    method: "get",
    params: data
  });
}

// 删除
export function deleteChain(data) {
  return request({ 
    url: "/system/industrialChain/deleteIndustrialChainByid",
    method: "post",
    data
  });
}

// 新增修改
export function addEditChain(data) {
  return request({
    url: "/system/industrialChain/addIndustrialChain",
    method: "post",
    data
  });
}
