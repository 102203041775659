<template>
  <div>
    <el-card>
      <el-tabs v-model="activeName">
        <el-tab-pane label="基本信息" name="first">
          <message-one
            :chainDatas="chainDatas"
            :personType="personType"
            ref="personMessage"
          ></message-one>
        </el-tab-pane>
        <el-tab-pane label="产业链上游" name="second">
          <chain-info
            :editTalentInfo.sync="data"
            :personType="personType"
            ref="top"
          ></chain-info>
        </el-tab-pane>
        <el-tab-pane label="产业链中游" name="third">
          <chain-info-middle
            :editTalentInfo.sync="data"
            :personType="personType"
            ref="middle"
          ></chain-info-middle>
        </el-tab-pane>
        <el-tab-pane label="产业链下游" name="fifth">
          <chain-info-bottom
            :editTalentInfo.sync="data"
            :personType="personType"
            ref="bottom"
          ></chain-info-bottom>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <div class="bottom">
      <el-button type="primary" @click="saveItem">保存</el-button>
      <el-button @click="cancelItem">取消</el-button>
    </div>
  </div>
</template>
<script>
import { getChainOne, addEditChain } from "@/api/industrialChain.js";
import MessageOne from "./message.vue";
import ChainInfo from "./chainInfo.vue";
import ChainInfoMiddle from "./chainInfoMiddle.vue";
import ChainInfoBottom from "./chainBottom.vue";
export default {
  components: {
    MessageOne,
    ChainInfo,
    ChainInfoBottom,
    ChainInfoMiddle,
  },
  // mounted() {
  //   if(this.personType){
  //     // document.querySelector('.el-tabs__content').style.height =
  //     // document.body.clientHeight - 310 + 'px'
  //   }else{
  //     document.querySelector('.el-tabs__content').style.height =
  //     document.body.clientHeight - 368 + 'px'
  //   }
  // },
  computed: {
    newsData() {
      return Number(this.$route.query.newsData);
    },
    personType() {
      return Number(this.$route.query.personType);
    },
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  created() {
    if (this.personType) {
      this.getChain();
    }
  },
  data() {
    return {
      activeName: "first",
      chainDatas: {
        supplyChainName: "",
        supplyChainDescribe: "",
        supplyChainIndustrys: [],
        supplyChainIndustryChild: [],
        father: null,
        knowTech: [],
      },
      data: {},
    };
  },
  methods: {
    async getChain() {
      const params = {
        byid: this.newsData,
      };
      const res = await getChainOne(params);
      if (res.code == 200) {
        this.$message.success("查询成功");
        this.chainDatas = res.data.chain;
        if (!this.chainDatas.fatherIds) {
          this.chainDatas.fatherIds = [];
        }
        if (this.chainDatas.sonIds.length > 0) {
          this.chainDatas.sonIds = this.chainDatas.sonIds.map((item) => {
            return (item = item.supplyChainName);
          });
          this.chainDatas.sonIds = this.chainDatas.sonIds.join(",");
        } else {
          this.chainDatas.sonIds = "";
        }
        this.data = res.data.maptree;

        this.data.上游.forEach((el) => {
          if (el.structLabels) {
            el.list = el.structLabels.map((item, index) => {
              return item.split(",");
            });
          }
          if (el.children) {
            el.children.forEach((o) => {
              if (o.structLabels) {
                o.list = o.structLabels.map((item, index) => {
                  return item.split(",");
                });
              }

              if (o.children) {
                o.children.forEach((i) => {
                  if (i.structLabels) {
                    i.list = i.structLabels.map((item, index) => {
                      return item.split(",");
                    });
                  }
                  if (i.children) {
                    i.children.forEach((u) => {
                      if (u.structLabels) {
                        u.list = u.structLabels.map((item, index) => {
                          return item.split(",");
                        });
                      }
                      if (u.children) {
                        u.children.forEach((a) => {
                          if (a.structLabels) {
                            a.list = a.structLabels.map((item, index) => {
                              return item.split(",");
                            });
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
        this.data.中游.forEach((el) => {
          if (el.structLabels) {
            el.list = el.structLabels.map((item, index) => {
              return item.split(",");
            });
          }
          if (el.children) {
            el.children.forEach((o) => {
              if (o.structLabels) {
                o.list = o.structLabels.map((item, index) => {
                  return item.split(",");
                });
              }

              if (o.children) {
                o.children.forEach((i) => {
                  if (i.structLabels) {
                    i.list = i.structLabels.map((item, index) => {
                      return item.split(",");
                    });
                  }
                  if (i.children) {
                    i.children.forEach((u) => {
                      if (u.structLabels) {
                        u.list = u.structLabels.map((item, index) => {
                          return item.split(",");
                        });
                      }
                      if (u.children) {
                        u.children.forEach((a) => {
                          if (a.structLabels) {
                            a.list = a.structLabels.map((item, index) => {
                              return item.split(",");
                            });
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
        this.data.下游.forEach((el) => {
          if (el.structLabels) {
            el.list = el.structLabels.map((item, index) => {
              return item.split(",");
            });
          }
          if (el.children) {
            el.children.forEach((o) => {
              if (o.structLabels) {
                o.list = o.structLabels.map((item, index) => {
                  return item.split(",");
                });
              }

              if (o.children) {
                o.children.forEach((i) => {
                  if (i.structLabels) {
                    i.list = i.structLabels.map((item, index) => {
                      return item.split(",");
                    });
                  }
                  if (i.children) {
                    i.children.forEach((u) => {
                      if (u.structLabels) {
                        u.list = u.structLabels.map((item, index) => {
                          return item.split(",");
                        });
                      }
                      if (u.children) {
                        u.children.forEach((a) => {
                          if (a.structLabels) {
                            a.list = a.structLabels.map((item, index) => {
                              return item.split(",");
                            });
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    },
    saveItem() {
      const data1 = {
        上游: this.$refs.top.data,
        中游: this.$refs.middle.data,
        下游: this.$refs.bottom.data,
      };

      const chainOb = {};
      Object.assign(chainOb, {
        maptree: data1,
        chain: this.$refs.personMessage.chainData,
      });
      let res;
      this.$refs.personMessage.$refs.chainRef.validate(async (valid) => {
        //async
        if (this.$refs.personMessage.chainData.fatherId == null) {
          this.$refs.personMessage.chainData.fatherId = 0;
        }
        if (valid) {
          if (!this.$refs.personMessage.chainData.fatherIds.length > 0) {
            this.$refs.personMessage.chainData.fatherIds = [{ id: 0 }];
          }
          if (this.$refs.personMessage.chainData.supplyChainIndustryChild) {
            this.$refs.personMessage.chainData.supplyChainIndustrys =
              this.$refs.personMessage.chainData.supplyChainIndustrys.concat(
                this.$refs.personMessage.chainData.supplyChainIndustryChild
              );
          }
          if (this.$refs.personMessage.chainData.sonIds !== "") {
            // this.$refs.personMessage.chainData.sonIds = this.$refs.personMessage.chainData.sonIds.split(",")
          } else {
            this.$refs.personMessage.chainData.sonIds = [];
          }
          if (this.$refs.personMessage.chainData.sonIds !== "") {
            // this.$refs.personMessage.chainData.sonIds = this.$refs.personMessage.chainData.sonIds.split(",")
          } else {
            this.$refs.personMessage.chainData.sonIds = [];
          }
          if (this.$refs.personMessage.chainData.sonIds !== "") {
            // this.$refs.personMessage.chainData.sonIds = this.$refs.personMessage.chainData.sonIds.split(",")
          } else {
            this.$refs.personMessage.chainData.sonIds = [];
          }
          const res = await addEditChain(chainOb);
          if (res.code == 200) {
            if (this.personType) {
              this.$message.success("修改成功");
              this.$router.go(-1);
            } else {
              this.$message.success("添加成功");
              this.$router.go(-1);
            }
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
    cancelItem() {
      this.$router.go(-1);
    },
  },
  mounted() {
    document.querySelector(".el-tabs__content").style.height =
      document.body.clientHeight - 290 + "px";
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-tabs__content {
  overflow: auto !important;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
/deep/ .el-textarea.is-disabled .el-textarea__inner {
  background-color: white;
  color: #606266;
  cursor: default;
}
</style>
